import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import InteractiveParagraph from "../../../V2/Lab/InteractiveParagraph"

export default function InteractiveParagraphStoryblok({
  blok,
}: Storyblok.BlokProps<Storyblok.InteractiveParagraph>) {
  return (
    <InteractiveParagraph
      headerText={blok.headerText}
      mode={blok.mode}
      content={blok.content}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}
